<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
    @font-face {
    font-family: OpenSans-SB;
    src: url(./assets/fonts/OpenSans-Semibold.ttf);
  }

  @font-face {
    font-family: OpenSans-B;
    src: url(./assets/fonts/OpenSans-Bold.ttf);
  }

  @font-face {
    font-family: OpenSans;
    src: url(./assets/fonts/OpenSans-Regular.ttf);
  }

  @font-face {
    font-family: OpenSans-L;
    src: url(./assets/fonts/OpenSans-Light.ttf);
  }
</style>
