import Vue from 'vue'
import VueRouter from 'vue-router' 

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: () => import('@/views/HomeView')
    },

    {
        path: '/about-us',
        name: 'AboutView',
        component: () => import('@/views/AboutView')
    },

    {
        path: '/products-services',
        name: 'ProdsServsView',
        component: () => import('@/views/ProdServsView')
    },

    {
        path: '/our-team',
        name: 'TeamView',
        component: () => import('@/views/TeamView')
    },

    {
        path: '/our-technology',
        name: 'TechnologyView',
        component: () => import('@/views/TechnologyView')
    },


]

const router = new VueRouter({
    routes
})

export default router